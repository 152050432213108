<template>
    <!-- 库存盘点 -->
<div>
    <a-card title="库存盘点">
        <template #extra>
            <a-space>
                <router-link to="/adhibition/storage/stock_taking_add">
                    <a-button type="primary" v-has="{action:'/adhibition/storage/stock_taking_add',plat:0}">
                        <i class="ri-add-line ri-top"></i>新增盘点
                    </a-button>
                </router-link>
                <com-upload-btn
                    v-has="{action:'wxapp_storage_customer_import',plat:0}"
                    btn-text="批量导入"
                    btn-type="default"
                    :customUpload="true"
                    @uploadChange="importStockTalk" >
                </com-upload-btn>
            </a-space>
        </template>
        <a-table :pagination="false" rowKey="id" :data-source="stState.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'盘点单号',dataIndex:'od'},
            {title:'仓库',dataIndex:'depot.name'},
            {title:'备注',dataIndex:'remark'},
            {title:'操作时间',dataIndex:'created_at'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]">
            <template #action="{record}">
                <router-link :to="{path:'/adhibition/storage/stock_taking_detail',query:{id:record.id}}">
                    <a-button type="link" v-has="{action:'/adhibition/storage/stock_taking_detail',plat:0}">详情</a-button>
                </router-link>
            </template>
        </a-table>

        <kd-pager :page-data="stState" :event="getStockTalkList"></kd-pager>
    </a-card>
</div>
</template>
<script>
import { useStockTalk  } from '@/models/addons/storage'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
export default {
    components:{
        comUploadBtn
    },
    setup() {
        let { stState,getStockTalkList,importStockTalk,exportStockTalk } = useStockTalk()
        getStockTalkList(1,stState.limit)
        return{
            stState,getStockTalkList,importStockTalk,exportStockTalk
        }
    },
}
</script>
<style scoped lang="scss">
.stock-taking{
    .pager{
        display: flex;
        justify-content: space-between;
    }
}
</style>
